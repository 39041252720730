import React from 'react';
import Sketch from 'react-p5';
import HelixaFont from './Helixa-Black.ttf';

const LorenzAttractor = () => {
  let time = 0;
  let points = [];
  let evo = 0;
  let x = 0;
  let y = 2;
  let z = 0;
  let mX = 0;
  let mY = 0;
  let frame = 0;

  let rand_seed = Math.random();

  let min_scale = 6;
  let max_scale = 15;

  let transY = 5;
  let transZ = -20;
  let transX = -20; //-30

  let rand_color;
  let satch;

  const fixWidth = (p5) => {
    if (p5.windowWidth < 1000) {
      transZ = -5;
    }
    if (p5.windowWidth < 900) {
      transZ = -6;
    }
    if (p5.windowWidth < 800) {
      transZ = -7;
    }
    if (p5.windowWidth < 700) {
      transZ = -8;
    }
    if (p5.windowWidth < 570) {
      transZ = -12;
    }
  };

  const windowResized = (p5) => {
    p5.resizeCanvas(p5.windowWidth, p5.windowHeight);
    fixWidth(p5);
    scale = p5.map(p5.windowWidth, 0, p5.windowWidth, min_scale, max_scale);
    console.log(scale);
  };

  let _text;
  let helixaFont;



  const setup = (p5, canvasParentRef) => {
    let cnv = p5.createCanvas(p5.windowWidth, p5.windowHeight, p5.WEBGL).parent(canvasParentRef);
    cnv.class('background_animation');
    p5.colorMode(p5.RGB);

    helixaFont = p5.loadFont(HelixaFont);
    initConditions(p5);
    _text = p5.createGraphics(window.innerWidth - 4, window.innerHeight - 4);
    _text.textFont(helixaFont);

    switch (Math.trunc(rand_seed * 10 / 3)) {
      default:
        rand_color = 250;
        break;
    }
  };

  let scale = 10;

  const initConditions = (p5) => {
    fixWidth(p5);
    scale = p5.map(p5.windowWidth, 0, p5.windowWidth, min_scale, max_scale);
    console.log(scale);

    points = [];
    time = 0;

    x = 0;
    y = Math.random() * 10 + 1;
    z = -2;

    mX = p5.mouseX;
    mY = p5.mouseY;

    for (let i = 0; i < 100; i++) {
      attract(p5, a, b, c, false);
      points.splice(0, 1);
    }
    for (let i = 0; i < 100; i++) {
      attract(p5, a, b, c, false);
    }
  };

  const attract = (p5, a, b, c, catchup) => {
    if (!catchup) {
      evo++;
    }
    let dt = 0.003;
    let dx = (a * (y - x)) * dt;
    let dy = (x * (b - z) - y) * dt;
    let dz = (x * y - c * z) * dt;

    x += dx;
    y += dy;
    z += dz;
    time += dt;
    points.push({ x: x + transX, y: y + transY, z: z + transZ });

    if (points.length > 1000) {
      // points.shift()
    }
  };

  const display = (p5) => {
    p5.background(255);


    p5.noFill();
    p5.strokeWeight(1.5);

    p5.rotateY(20);

    p5.push();
    p5.translate(-100, 0, 0); // Translate the entire animation to the left
    let hu = 0;
    let check = 0;
    p5.beginShape();
    for (let p = 0; p < points.length - 5; p++) {
      check += 1;
      let greyValue = p5.map(p, 0, points.length, 255, 200);
      p5.stroke(greyValue);

      p5.vertex(points[p].x * scale, points[p].y * scale, points[p].z * scale);
      hu += 0.5;
      if (hu > 255) {
        hu = 0;
      }
      if (check === 2) {
        p5.endShape();
        check = 0;
        p5.beginShape();
        p5.vertex(points[p].x * scale, points[p].y * scale, points[p].z * scale);
      }
    }
    p5.endShape();
    p5.translate(points[points.length - 1].x * scale, points[points.length - 1].y * scale, points[points.length - 1].z * scale);
    p5.fill(200);
    p5.noStroke();
    _text.clear()

    _text.textAlign(p5.CENTER);
    _text.textSize(100);
    _text.fill(150);
    _text.noStroke();
    _text.text(`${Math.abs(Math.round(z))} inches`, p5.width * 0.5, p5.height * 0.5);

    p5.texture(_text);
    let inverseNumSize = 8;
    p5.plane(window.innerWidth / inverseNumSize, window.innerHeight / inverseNumSize);
    // p5.sphere(1.5); // Draw the dot
    p5.pop();


  };

  const draw = (p5) => {
    frame++;

    attract(p5, a, b, c, false);

    if (points.length > 100) {
      points.splice(0, 1);
    }

    display(p5);
  };

  let a = 10;
  let b = 28;
  let c = 8 / 3;

  return <Sketch setup={setup} draw={draw} windowResized={windowResized} />;
};

export default LorenzAttractor;