import React from "react";
import logo from './logo.png';
import { Link } from 'react-router-dom';
import './index.css';

const Header = () => {
  return (
    <div className='fixed top-0 left-0 text-black flex justify-between w-full'>
      <Link to="/">
        <img src={logo} className="p-5 w-full max-w-[150px] sm:max-w-[160px] pointer-events-none" alt="logo" />
      </Link>
      <div className='flex font-bold font-helixa-regular mt-6 mr-3'>
        <Link className='px-2'>Get Extension</Link>

      </div>
    </div >

  );
}

export default Header;