import React from 'react';
import { Link } from 'react-router-dom';
import logo from './logo.png';
import jumper from './imgs/jumper4.png';
import './index.css';
import LorenzAttractor from './LorenzAttractor';
import GrainBackdrop from './GrainBackdrop';
import Header from './Header';
import Background from './Background';

function App() {
  const arr0 = ['#C0FDFB', '#64B6AC', '#FAD4C0', '#FEE9E1', '#B09E99'];
  const arr1 = ['#F9D689', '#FEFFD2', '#FFBF78', '#FFEEA9', '#F0A500'];
  const arr2 = ['#FEA1A1', '#ECCDB4', '#F0EDD4', '#F9FBE7', '#FF7D29'];
  const arr3 = ['#fff5d7', '#ECCDB4', '#ECCDB4', '#ECCDB4', '#ECCDB4'];
  const arr4 = ['#E0BFB8', '#F8C8DC', '#F3CFC6', '#C9A9A6', '#F2D2BD'];
  const arr5 = ['#90f6d7', '#35bcbf', '#FAD4C0', '#FEE9E1', '#B09E99'];

  // Array of taglines with specific word italicized
  const taglines = {
    0: { textBefore: "", italic: "feel good", textAfter: "sunday" }, // Sunday
    1: { textBefore: "made for", italic: "you", textAfter: "monday" }, // Monday
    2: { textBefore: "", italic: "tailored", textAfter: "tuesday" }, // Tuesday
    3: { textBefore: "hump", italic: "fitting", textAfter: "day" }, // Wednesday
    4: { textBefore: "", italic: "tailored", textAfter: "thursday" }, // Thursday
    5: { textBefore: "", italic: "perfect", textAfter: "fit friday" }, // Friday
    6: { textBefore: "", italic: "slay saturday", textAfter: "" } // Saturday
  };

  // Get the current day of the week (0 is Sunday, 6 is Saturday)
  const currentDay = new Date().getDay();
  const { textBefore, italic, textAfter } = taglines[currentDay];

  return (
    <div className="relative">
      <div className="fixed top-0 left-0 w-full h-full z-0">
        <Background />
      </div>

      <div className="relative z-10 text-center">
        <header className="min-h-screen flex flex-col items-center justify-center text-white">
          <Header />
          <p className='md:px-5 text-slate-900 text-5xl sm:text-5xl md:text-6xl lg:text-7xl mt-5 font-helixa-regular font-bold text-center'>
            <img src={jumper} className="p-5 mt-[-100px] w-full max-w-[400px] sm:max-w-[450px] pointer-events-none mx-auto opacity-90 grayscale" alt="logo" />
            <div className='font-normal text-3xl mt-[-80px]'>Clothing in the</div>
            <span className='font-helixa-black'>
              Image of you
            </span>
            {/* {textBefore} <i>{italic}</i> {textAfter} */}
          </p>
          <div className='flex space-x-4 mt-16 text-xl sm:text-2xl'>
            <Link to="/scan" className="relative btn-slide-show font-helixa-regular px-10 md:px-20">
              <p className="btn-slide-show-text1">Let's go!</p>
              <div className="btn-slide-show-inner">
                <p className="btn-slide-show-text2">Start →</p>
              </div>
            </Link>
            <Link
              className="text-black hover:text-white/70 px-10 md:px-20 py-2"
              to="/about"
            >
              About
            </Link>
          </div>
        </header>


      </div>
    </div>
  );
}

export default App;
