import React from "react";
import GrainBackdrop from './GrainBackdrop';
import './Background.css'; // Import the CSS file

const Background = () => {
  const arr0 = ['#7da9ff', '#80ffeb', '#ffb997', '#ffe9d6', '#ff6c47'];



  return (
    <div className='background-container'>
      <GrainBackdrop animateColors={false} fixedColors={arr0} />
    </div>
  );
}

export default Background;
